import React from 'react'
import user1 from '../../images/svg/box/users/user1.svg'
import user2 from '../../images/svg/box/users/user2.svg'
import user3 from '../../images/svg/box/users/user3.svg'
import user4 from '../../images/svg/box/users/user4.svg'
import api from '../../images/svg/box/api.svg'
import floatApproved from '../../images/svg/box/float/approved.svg'
import floatRequested from '../../images/svg/box/float/request.svg'
import floatDisbursed from '../../images/svg/box/float/disbursed.svg'
import floatOutstanding from '../../images/svg/box/float/outstanding.svg'
import floatOverdue from '../../images/svg/box/float/overdue.svg'
import cl from '../../images/svg/box/api/cl.svg'
import av from '../../images/svg/box/api/av.svg'
import bv from '../../images/svg/box/api/bv.svg'
import cv from '../../images/svg/box/api/cv.svg'
import nv from '../../images/svg/box/api/nv.svg'
import cr from '../../images/svg/box/api/cr.svg'
import crc from '../../images/svg/box/api/crc.svg'
import fc from '../../images/svg/box/api/fc.svg'
import income from '../../images/svg/box/api/income.svg'
import pr from '../../images/svg/box/api/pr.svg'
import radar from '../../images/svg/box/api/radar.svg'
import appBlue from '../../images/svg/box/app/appBlue.svg'
import appGreen from '../../images/svg/box/app/appGreen.svg'
import graph from '../../images/svg/box/graph.svg'
import graphRed from '../../images/svg/box/graphRed.svg'


const Box = ({boxData, devBoxData, query, doAction}) => {
  //third-party
  const thirdPartyCount = (boxData?.item?.totalCount ? boxData?.item?.totalCount : 0) + (devBoxData?.totalCount ? devBoxData?.totalCount : 0);
  const thirdPartyCost = (boxData?.item?.totalCost ? boxData?.item?.totalCost : 0) + (devBoxData?.totalCost ? devBoxData?.totalCost : 0);

  var formatMoney = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    //minimumFractionDigits: 0, 
    maximumFractionDigits: 2, 
  });
  return  (

   <>
   {
     //users
     boxData.boxFor === "users" ?
    <div className='box_container'>
   
        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={user1} alt="icon" /></div>

          <div className='box_container-box-details'>
          <h5> {boxData?.item?.totalUsersOnboarded}</h5>
            <p>Total Users Onboarded</p>
          </div>

        </div>

        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={user2} alt="icon" /></div>

          <div className='box_container-box-details'>
            <h5> {boxData?.item?.totalChecksMade}</h5>
            <p>Total Checks Made</p>
          </div>

        </div>

        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={user3} alt="icon" /></div>

          <div className='box_container-box-details'>
            <h5> {boxData?.item?.usersOnboarded}</h5>
              <p>Users Onboarded</p>
              <p style={{display:"flex"}}> 
                <div style={{flex:2}}> 
                  <img src={boxData?.item?.userPercent < 0 ? graphRed : graph} alt="icon" />
                  <span className={boxData?.item?.userPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.userPercent) || 0}%</span> 
                </div>
              
              <span className='small-text' style={{flex: 1.7}}> {query} </span> 
              </p>
          </div>

        </div>

        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={user4} alt="icon" /></div>

            <div className='box_container-box-details'>
            <h5> {boxData.item.checksMade}</h5>
              <p>Checks Made</p>
              <p style={{display:"flex"}}> 
              <div style={{flex:2}}> 
                  <img src={boxData?.item?.checkPercent < 0 ? graphRed : graph} alt="icon" />
                  <span className={boxData?.item?.checkPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.checkPercent) || 0}%</span> 
                  </div>
              
              <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                </p>
          </div>
        </div>
    </div>
    : 
     //third-party-apps
     boxData.boxFor === "third-party-apps" ?
    <div className='box_container'>
   
        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={appBlue} alt="icon" /></div>

          <div className='box_container-box-details'>
            <h5> {thirdPartyCount ? thirdPartyCount : 0}</h5>
            <p>Total API Calls</p>
          </div>
        </div>

        <div className='box_container-box'>
          <div className='box_container-box-image'> <img src={appGreen} alt="icon" /></div>
          <div className='box_container-box-details'>
            <h5> { thirdPartyCost ? formatMoney.format(thirdPartyCost) : formatMoney.format(0) }</h5>
            <p>Total Value</p>
          </div>
        </div>
    </div>
    : 

    //apis
    boxData.boxFor === "apis" ?
    <div className='box_container'>
          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={api} alt="icon" /></div>

            <div className='box_container-box-details'>
            <h5> {boxData?.item?.totalApiCalls}</h5>
              <p>Total API Calls</p>
              { query !== 'all' &&
              <p style={{display:"flex"}}> 
             
                <div style={{flex:2}}> 
                    <img src={boxData?.item?.totalApisPercent < 0 ? graphRed : graph} alt="icon" />
                    <span className={boxData?.item?.totalApisPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.totalApisPercent) || 0}%</span> 
                </div>
                <span className='small-text' style={{flex: 1.7}}> {query} </span> 
             
                </p>
               }
            </div>
          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={fc} alt="icon" /></div>

            <div className='box_container-box-details'>
            <h5> {boxData?.item?.firstCentral}</h5>
              <p>First Central</p>
              { query !== 'all' &&
               <p style={{display:"flex"}}> 
                  <div style={{flex:2}}> 
                    <img src={boxData?.item?.firstCentralPercent < 0 ? graphRed : graph} alt="icon" />
                    <span className={boxData?.item?.firstCentralPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.firstCentralPercent) || 0}%</span> 
                  </div>
              
                 <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                </p>
              }
            </div>
          </div>

          <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={radar} alt="icon" /></div>

            <div className='box_container-box-details'>
              <h5> {boxData?.item?.radar}</h5>
                <p>Radar</p>
                { query !== 'all' &&
                  <p style={{display:"flex"}}> 
                    <div style={{flex:2}}> 
                      <img src={boxData?.item?.radarPercent < 0 ? graphRed : graph} alt="icon" />
                      <span className={boxData?.item?.radarPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.radarPercent) || 0}%</span> 
                    </div>
                
                    <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                  </p>
                }
            </div>

          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={bv} alt="icon" /></div>

              <div className='box_container-box-details'>
              <h5> {boxData?.item?.bvnVerication}</h5>
                <p>BVN Verification</p>
                { query !== 'all' &&
                  <p style={{display:"flex"}}> 
                    <div style={{flex:2}}> 
                      <img src={boxData?.item?.bvnPercent < 0 ? graphRed : graph} alt="icon" />
                      <span className={boxData?.item?.bvnPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.bvnPercent) || 0}%</span> 
                    </div>

                    <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                  </p>
                }
            </div>

          </div>
          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={api} alt="icon" /></div>

            <div className='box_container-box-details'>
            <h5> {boxData?.item?.totalBillableApiCalls}</h5>
              <p>Total billable API calls</p>
              { query !== 'all' &&
                <p style={{display:"flex"}}> 
                  <div style={{flex:2}}> 
                    <img src={boxData?.item?.totalBillablePercent < 0 ? graphRed : graph} alt="icon" />
                  <span className={boxData?.item?.totalBillablePercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.totalBillablePercent) || 0}%</span> 
                  </div>
              
                   <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                </p>
              }
            </div>
          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={crc} alt="icon" /></div>

            <div className='box_container-box-details'>
            <h5> {boxData?.item?.crc}</h5>
              <p>CRC</p>
              { query !== 'all' &&
                <p style={{display:"flex"}}> 
                  <div style={{flex:2}}> 
                    <img src={boxData?.item?.crcPercent < 0 ? graphRed : graph} alt="icon" />
                    <span className={boxData?.item?.crcPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.crcPercent) || 0}%</span> 
                  </div>
                
                   <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                  </p>
              }
            </div>
          </div>

          <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={cl} alt="icon" /></div>

            <div className='box_container-box-details'>
              <h5> {boxData?.item?.classic}</h5>
                <p>Classic</p>
                { query !== 'all' &&
                  <p style={{display:"flex"}}> 
                    <div style={{flex:2}}> 
                      <img src={boxData?.item?.classicPercent < 0 ? graphRed : graph} alt="icon" />
                      <span className={boxData?.item?.classicPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.classicPercent) || 0}%</span> 
                    </div>
                
                    <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                  </p>
                }
            </div>

          </div>
          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={income} alt="icon" /></div>

            <div className='box_container-box-details'>
              <h5> {boxData?.item?.income}</h5>
              <p>Income</p>
              { query !== 'all' &&
                <p style={{display:"flex"}}> 
                  <div style={{flex:2}}> 
                    <img src={boxData?.item?.incomePercent < 0 ? graphRed : graph} alt="icon" />
                    <span className={boxData?.item?.incomePercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.incomePercent) || 0}%</span> 
                  </div>
                
                  <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                </p>
              }
            </div>

          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={cr} alt="icon" /></div>

              <div className='box_container-box-details'>
              <h5> {boxData?.item?.creditRegistry}</h5>
                <p>Credit Registry</p>
                { query !== 'all' &&
                  <p style={{display:"flex"}}> 
                    <div style={{flex:2}}> 
                        <img src={boxData?.item?.classicPercent < 0 ? graphRed : graph} alt="icon" />
                        <span className={boxData?.item?.classicPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.classicPercent) || 0}%</span> 
                      </div>
              
                    <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                  </p>
                }
            </div>

          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={pr} alt="icon" /></div>

              <div className='box_container-box-details'>
              <h5> {boxData?.item?.premium}</h5>
                <p>Premium</p>
                { query !== 'all' &&
                  <p style={{display:"flex"}}> 
                    <div style={{flex:2}}> 
                      <img src={boxData?.item?.premiumPercent < 0 ? graphRed : graph} alt="icon" />
                      <span className={boxData?.item?.premiumPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.premiumPercent) || 0}%</span> 
                    </div>
              
                  <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                  </p>
                }
            </div>

          </div>
          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={av} alt="icon" /></div>

            <div className='box_container-box-details'>
              <h5> {boxData?.item?.accountVerication}</h5>
              <p>Account Number Verification</p>
              { query !== 'all' &&
                <p style={{display:"flex"}}> 
                  <div style={{flex:2}}> 
                    <img src={boxData?.item?.accountVerificationPercent < 0 ? graphRed : graph} alt="icon" />
                    <span className={boxData?.item?.accountVerificationPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.accountVerificationPercent) || 0}%</span> 
                  </div>
                  
                  <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                </p>
              }
            </div>

          </div>

            <div className='box_container-box'>

              <div className='box_container-box-image'> <img src={cv} alt="icon" /></div>

                <div className='box_container-box-details'>
                <h5> {boxData?.item?.cacVerication}</h5>
                  <p>CAC Verification</p>
                  { query !== 'all' &&
                    <p style={{display:"flex"}}> 
                      <div style={{flex:2}}> 
                        <img src={boxData?.item?.cacPercent < 0 ? graphRed : graph} alt="icon" />
                        <span className={boxData?.item?.cacPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.cacPercent) || 0}%</span> 
                      </div>
                
                      <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                    </p>
                  }
              </div>

            </div>

            <div className='box_container-box'>

              <div className='box_container-box-image'> <img src={nv} alt="icon" /></div>

                <div className='box_container-box-details'>
                <h5> {boxData?.item?.ninVerication}</h5>
                  <p>NIN Verification</p>
                  { query !== 'all' &&
                    <p style={{display:"flex"}}> 
                      <div style={{flex:2}}> 
                          <img src={boxData?.item?.ninPercent < 0 ? graphRed : graph} alt="icon" />
                          <span className={boxData?.item?.ninPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.ninPercent) || 0}%</span> 
                        </div>
                
                        <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                    </p>
                  }
              </div>

            </div>
        
    </div>
     :
      //float
      boxData.boxFor === "float" ?
      <div className='box_container'>
     
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatRequested} alt="icon" /></div>
  
            <div className='box_container-box-details'>
            <h5> {boxData?.item?.totalRequestReceived}</h5>
              <p>Total Request Received</p>
              {/* <p> <img src={graph} alt="icon" />
                <span className='percent'> 4.07%</span> 
                <span className='small-text'> Last month </span> 
              </p> */}
            </div>
  
          </div>
  
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatApproved} alt="icon" /></div>
  
            <div className='box_container-box-details'>
              <h5> {boxData?.item?.totalApproved}</h5>
              <p>Total Approved</p>
              {/* <p> <img src={graph} alt="icon" />
                <span className='percent'> 4.07%</span> 
                <span className='small-text'> Last month </span> 
              </p> */}
            </div>
  
          </div>
  
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatDisbursed} alt="icon" /></div>
  
            <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalValueDisbursed ? boxData?.item?.totalValueDisbursed : 0)}</h5>
                <p>Total Value Disbursed</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
  
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatOutstanding} alt="icon" /></div>
  
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalOutstanding ? boxData?.item?.totalOutstanding : 0)}</h5>
                <p>Total  Outstanding</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
  
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatOverdue} alt="icon" /></div>
  
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalOverdue ? boxData?.item?.totalOverdue : 0)}</h5>
                <p>Total Overdue</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatDisbursed} alt="icon" /></div>
  
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalValueRepayed ? boxData?.item?.totalValueRepayed : 0)}</h5>
                <p>Total Value Repayed</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatDisbursed} alt="icon" /></div>
  
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.expectedCashflow ? boxData?.item?.expectedCashflow : 0)}</h5>
                <p>Expected Cashflow</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
      </div>
    :
      //float-history
      boxData.boxFor === "float-history" ?
      <div className='box_container'>
  
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatDisbursed} alt="icon" /></div>
  
            <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalValueDisbursed ? boxData?.item?.totalValueDisbursed : 0)}</h5>
                <p>Total Value Disbursed</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>

          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatDisbursed} alt="icon" /></div>
  
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalValueRepayed ? boxData?.item?.totalValueRepayed : 0)}</h5>
                <p>Total Value Repayed</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
  
         
          <div className='box_container-box'>
  
          <div className='box_container-box-image'> <img src={floatOutstanding} alt="icon" /></div>

              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalOutstanding ? boxData?.item?.totalOutstanding : 0)}</h5>
                <p>Total  Outstanding</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>

          </div>

          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatOverdue} alt="icon" /></div>
  
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalOverdue ? boxData?.item?.totalOverdue : 0)}</h5>
                <p>Total Overdue</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
          
      </div>
    :<div></div>
}
    </>
  )
}

export default Box
