import { Route, Routes } from 'react-router-dom';
import Details from './Details';
import Main from './Main';


const TechSupport = () => {
    return (
        <Routes>
            <Route path="/" element={<Main />}/>
            <Route path="/:service" element={<Details />} />
        </Routes>
    )
}

export default TechSupport

