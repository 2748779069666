import axios from 'axios';

let token = '';
let walletToken = process.env.REACT_APP_WALLET_TOKEN
let userRole = JSON.parse(sessionStorage.getItem('eagleEye_details'))?.adminRole
let adminName = JSON.parse(sessionStorage.getItem('adminName'))

let APIKit = axios.create({
        baseURL: process.env.REACT_APP_ENVIRONMENT === "production" ?
                process.env.REACT_APP_PRODUCTION :
                process.env.REACT_APP_LOCAL,
        timeout: 100000, // timeout can be increased
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin' : '*',
            'role': userRole,
            'name': adminName
        },
    });
let APIKat = axios.create({
        baseURL: process.env.REACT_APP_PRODUCTION,
        timeout: 10000, // timeout can be increased
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin' : '*',
            'role': userRole,
            'name': adminName
        },
    });
let APIProd = axios.create({
    baseURL: "https://api.creditchek.africa/v1/",
    timeout: 10000, // timeout can be increased
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
    },
});

const loadToken = () => {
    try {
        token = JSON.parse(sessionStorage.getItem('eagleEye_details')).token;
    } catch (err) {
        //err
    }
}

//signin, login, logout---------->>>
export const SignupAction = (adminId, payload) => {
    return APIKit.post(`eagleeyeauth/register/${adminId}`, payload)
}

export const LoginAction = (payload) => {
    return APIKit.post(`eagleeyeauth/login`, payload)
}

export const ForgotPasswordAction = (payload) => {
    return APIKit.post(`eagleeyeauth/forgot-password`, payload)
}

export const ResetPasswordAction = (reset_token, payload) => {
    return APIKit.post(`eagleeyeauth/reset-password?token=${reset_token}`, payload)
}

export const eagleeyeauthLogs = (payload) => { // get admin log
    return APIKit.post(`eagleeyeauth/admin-log`, payload)
}

export const logoutAction = (payload) => {
    return APIKit.post(`eagleeyeauth/logout`, payload)
}


//admin---------->>>
export const getAllAdmin = () => {
    return APIKit.get(`eagleeyeauth/admin/team`)
}

export const getAdminById = (id) => {
    return APIKit.get(`eagleeyeauth/admin/${id}`)
}

export const inviteAdmin = (payload) => {
    return APIKit.post(`eagleeyeauth/team-invite`, payload)
}

export const changeAdminPassword = (id, payload) => {
    return APIKit.put(`eagleeyeauth/admin/change-password/${id}`, payload)
}

export const editAdminProfile = (payload) => {
    return APIKit.put(`eagleeyeauth/admin/profile/edit`, payload)
}

export const deleteAdmin = (id) => {
    return APIKit.delete(`eagleeyeauth/admin/delete/${id}`)
}


//approvals---------->>>

export const approvalAction = (payload, ipDeets ) => {
    loadToken();
    return APIKit.put(`eagleeyeauth/business/status/action`, payload, {
        headers: {
            'ipcountry': ipDeets.country_name,
            'ipaddress': ipDeets.IPv4,
            Authorization: `Bearer ${token}`
        }
    })
}

export const deleteBusiness = (payload, ipDeets) => {
    loadToken();
    return APIKit.delete(`eagleeyeauth/business/delete/${payload}`, {
        headers: {
            'ipcountry': ipDeets.country_name,
            'ipaddress': ipDeets.IPv4,
            Authorization: `Bearer ${token}`
        }
    })
}


//business stats---------->>>
export const businessInfo = (payload) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/business/business-info?type=${payload}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

//bank stats---------->>>
export const getAllBankLogo = () => {
    return APIProd.get(`/digitalassets/bank/get-all`
    )
}

//user stats---------->>>
export const userStats = (payload) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/user/stats?type=${payload}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

//api stats---------->>>
export const apiStats = (payload) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/api/stats?type=${payload}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

//userconsent stats
export const userConsentStats = (payload) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/userconsent/stats?type=${payload}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

//third party stats---------->>>
export const thirdPartyStats = (payload) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/thirdparty/stats?type=${payload}`,
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}
export const devThirdPartyStats = (payload) => {
    loadToken();
    return APIKat.get(`billing/thirdparty/stats?type=${payload}`,
    {
        headers: {
            token: walletToken
        }
    }
    )
}

export const lifeGateStats = (payload) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/life-gate/stats?type=${payload}`, {
        headers: {
            'role': 'Life Gate',
            Authorization: `Bearer ${token}`
        },
    })
}

export const devLifeGateStats = (payload) => {
    loadToken();
    return APIKat.get(`billing/life-gate/stats?type=${payload}`, {
        headers: {
            'role': 'Life Gate',
            token: walletToken
        },
    })
}

//logs
export const getApprovalLogs = (query) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/approval-log?type=${query}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

//role based access control ---------->>>

//modules
export const getAllModules = () => {
    loadToken();
    return APIKit.get(`eagleeyeauth/modules`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

//roles
export const getAllRoles= () => {
    loadToken();
    return APIKit.get(`eagleeyeauth/admin/role/fetch`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const getRolesById= (id) => {
    loadToken();
    return APIKit.post(`eagleeyeauth/admin/role/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}
export const getRolesByAdminName= (payload) => {
    loadToken();
    return APIKit.post(`eagleeyeauth/admin/role/fetch/name`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const editRole = (id, payload) => {
    loadToken();
    return APIKit.put(`eagleeyeauth/admin/role-edit/${id}`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const createRole = (payload) => {
    loadToken();
    return APIKit.post(`eagleeyeauth/admin/role-create`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}


//tech-support ---------->>>
export const getLogService = (payload) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/support/logs?service=${payload}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const getBin = (id, payload) => {
    loadToken();
    return APIKit.post(`eagleeyeauth/support/get-bin/${id}`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const getUpdatedBin = (payload, id) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/support/logs?service=${payload}&binId=${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

//metrics stats---------->>>
export const metricsStats = (payload) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/metrics/stats?type=${payload}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

//float---------->>>
export const floatRequests = (payload, query, amountType) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/float/status/${payload}?type=${query}&amountType=${amountType}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const floatAction = (payload, ipDeets ) => {
    loadToken();
    return APIKit.put(`eagleeyeauth/float/status/action`, payload, {
        headers: {
            'ipcountry': ipDeets.country_name,
            'ipaddress': ipDeets.IPv4
        }
    })
}

export const floatStats = (query, amountType) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/float/stats?type=${query}&amountType=${amountType}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const viewFloatRequest = (payload) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/float/get-float/${payload}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const makeFloatOffer = (payload) => {
    loadToken();
    return APIKit.post(`eagleeyeauth/float/get-float-offer`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const declineFloatOffer = (id) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/float/decline-float-offer/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const getFloatBreakdown = (payload) => {
    loadToken();
    return APIKit.post(`eagleeyeauth/float/generate-breakdown`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}
//total float history
export const floatPaymentHistory = (query) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/float/payment-history?type=${query}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}


//float repayment by floatId
export const repaymentHistoryById = (id) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/float/repayment-history-id/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const floatDirectorConsent = (payload) => {
    loadToken();
    return APIKit.post(`eagleeyeauth/float/director-consent`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const changeFloatStatus = (payload) => {
    loadToken();
    return APIKit.put(`eagleeyeauth/float/:status/action`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const sendDisburseMail = (payload) => {
    loadToken();
    return APIKit.post(`eagleeyeauth/float/disbursed-mail`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const floatUpcomingPayment = (query, amountType) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/float/upcoming-payment?type=${query}&amountType=${amountType}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const activateDefaultFee = (floatId) => {
    loadToken();
    return APIKit.put(`eagleeyeauth/float/${floatId}/activate-lien`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const deactivateDefaultFee = (floatId) => {
    loadToken();
    return APIKit.put(`eagleeyeauth/float/${floatId}/deactivate-lien`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const repayFloatAction = (payload) => {
    loadToken();
    return APIKit.post(`billing/float-repayment`, payload, {
        baseURL: "https://api.creditchek.africa/v1/",
        headers: {
            token: `2ad109e692e279927fa7471a54a73f01`
        }
    })
}

export const getFloatLiquidationDetails = (payload) => {
    loadToken();
    return APIKit.post(
        `billing/float-repayment-details`,
        payload,
        {
            baseURL: "https://api.creditchek.africa/v1/",
            headers: {
                token: `2ad109e692e279927fa7471a54a73f01`
            }
        }
    )
}


// float patch
export const getFloatPatchStats = () => {
    loadToken();
    return APIKit.get(`eagleeyeauth/float/patch-stats`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const getFloatPatchReqs = () => {
    loadToken();
    return APIKit.get(`eagleeyeauth/float/patch-data`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const getFloatPatchByID = (id) => {
    loadToken();
    return APIKit.get(`eagleeyeauth/float/patch/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const declineFloatPatch = (id) => {
    loadToken();
    return APIKit.put(`eagleeyeauth/float/patch/${id}/reject`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const acceptFloatPatch = (id) => {
    loadToken();
    return APIKit.put(`eagleeyeauth/float/patch/${id}/approve`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}


//api-service
export const createApiService = (payload) => {
    loadToken();
    return APIKit.post(`eagleeyeauth/api-security/create-service`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const getApiService = () => {
    loadToken();
    return APIKit.get(`eagleeyeauth/api-security/services`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const generateRefreshKey = (payload) => {
    loadToken();
    return APIKit.post(`eagleeyeauth/api-security/refresh-key`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

//flutterwave
export const getDailyCurrencyRate = () => {
    loadToken();
    return APIKit.get(`eagleeyeauth/float/daily-currency-rate`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const sendEmails = (payload) => {
    loadToken();
    return APIKit.post(`eagleeyeauth/email/send`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}
