import { APIKit, loadToken } from "services";

const timeout = 15000;

let walletToken = process.env.REACT_APP_WALLET_TOKEN

export const WalletService = {
    getTotalFunded: (args) => {
        return APIKit(timeout).get(`/wallet/stats`, {
            headers: {
                token: walletToken
            },
            params:{
                ...args,
            }
        })
    },

    prodIncomeActivity: (args) => {
        return APIKit(timeout).get(`eagleeyeauth/wallet/income-graph`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params:{
                ...args,
            }
        })
    },

    verifyWallet: (businessId, env) => {
        return APIKit(timeout).post(`wallet/eagleeye/verify-wallet/${businessId}`, {}, {
            baseURL: env==="development" ? process.env.REACT_APP_PRODUCTION : "https://api.creditchek.africa/v1/",
            headers: {
                token: walletToken
            }
        })
    },

    rechargeWallet: (payload, env) => {
        return APIKit(timeout).post(`wallet/recharge/admin`, payload, {
            baseURL: env==="development" ? process.env.REACT_APP_PRODUCTION : "https://api.creditchek.africa/v1/",
            headers: {
                token: walletToken
            }
        })
    },

    getAllTopUp: (type) => {
        return APIKit(timeout).get(`eagleeyeauth/wallet/topup?type=${type}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    walletStats: (args) => {
        return APIKit(timeout).get(`eagleeyeauth/walletstats`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params:{
                ...args,
            }
        })
    },

    walletTransaction: (args) => {
        return APIKit(timeout).get(`eagleeyeauth/wallet/transactions`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params:{
                ...args,
            }
        })
    },
}