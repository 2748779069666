import React, { useEffect, useState } from 'react'
import { handleRequestErrors } from 'utils/helpers';
import { TechSupportService } from 'services/techsupport.service';
import tech from 'images/svg/box/tech.svg';
import DataBox from 'components/shared/DataBox';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';


export default function HomeComponent () {
    const [data, setData] = useState(null);
    let navigate = useNavigate()

    useEffect(() => {
        TechSupportService.getUpdatedAt()
            .then(response => response.data)
            .then((res) => {
                if(res.status === 'success'){
                    setData(res?.data);
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='grid grid-cols-3 gap-6 my-10'>
            {
                [
                    {
                        title: "Auth",
                        date: data?.Authentication,
                    },
                    {
                        title: "Income",
                        date:  data?.IncomeInsights,
                    },
                    {
                        title: "Wallet",
                        date:  data?.Wallet,
                    },
                    {
                        title: "Credit",
                        date:  data?.Creditinsights,
                    },
                    {
                        title: "Identity",
                        date:  data?.Identity,
                    },
                    {
                        title: "Radar",
                        date:  data?.Radar,
                    },
                    {
                        title: "Pdf Parser",
                        date:  data?.PDFParser,
                    },
                    {
                        title: "DBR & EDTI",
                        date:  data?.dbrEDTI,
                    },
                    {
                        title: "B2BFrontend",
                        date:  data?.FrontendB2B,
                    },
                    {
                        title: "Float",
                        date:  data?.Float,
                    },
                    {
                        title: "MFB",
                        date:  data?.Float,
                    },
                    {
                        title: "Spectrum",
                        date:  data?.Float,
                    },
                    {
                        title: "Eagleeye",
                        date:  data?.EagleEyeAPI,
                        key: "EagleEyeAPI"
                    },
                    {
                        title: "Recova",
                        date:  data?.dln,
                        key: "dln"
                    },
                ].map(item => (
                    <div 
                        key={item.title}
                        onClick={()=>{
                            navigate(`/tech-support/${item.key || item.title}`)
                        }}
                    >
                        <DataBox 
                            title={(
                                <span>
                                    Last Updated <br />
                                    {dayjs(item.date).format("DD-MM-YYYY, hh:mmA")}
                                </span>
                            )}
                            icon={<img src={tech} alt={item.title} className='w-[20px]' />}
                            value={item.title}
                            className="!shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)] cursor-pointer"
                        />
                    </div>
                ))
            }
        </div>
    )
}