import React, { useState } from 'react'
import home from 'images/svg/float/home.svg'
import paymentHistory from 'images/svg/float/paymentHistory.svg'
import clsx from 'clsx'
import HomeComponent from './components/HomeComponent';
import ApiSecurity from './components/ApiSecurity';

const Main = () => {
  const [requestPath, setRequestPath] = useState("home");

  return (
    <div>
      <div className='flex gap-6'>
        {
          [
            {
              label: "Home",
              icon: home,
              key: "home",
            },
            {
              label: "Api Security",
              icon: paymentHistory,
              key: "api-security",
            }
          ].map((item) => (
            <div 
              className={clsx(
                "flex items-center gap-1.5 cursor-pointer pb-[5px] border-b-[2.5px] border-b-transparent",
                requestPath===item.key && "!border-b-[#427CFF]",
              )} 
              onClick={()=> { 
                setRequestPath(item.key)
              }}
            >
              <img src={item.icon} alt="icon" />
              <p className='font-normal font-medium text-base text-[#424242]'>{item.label}</p>
            </div>
          ))
        }
      </div>
      {
        requestPath === "home" 
        ? <HomeComponent />
        : <ApiSecurity />
      }
    </div>
  )
}

export default Main