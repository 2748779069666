import React, { useEffect, useState } from 'react'
import Table from 'components/Table';
import { toast } from 'react-toastify';
import { FormikProvider, useFormik } from 'formik';
import { SelectField } from 'components/Form/SelectField';
import { TextField } from 'components/Form/TextField';
import { CurrencyField } from 'components/Form/CurrencyField';
import Button from 'components/shared/Button';
import { currencyFormatter, handleRequestErrors } from 'utils/helpers';
import { WalletService } from 'services/wallet.service';
import { object, string } from "yup";
import dayjs from 'dayjs';
import SearchField from 'components/shared/SearchField';
import { useSearchParams } from 'react-router-dom';
import { mkConfig, generateCsv, download } from "export-to-csv";


function WalletTopup () {
    const [isLoading, setIsLoading] = useState({
        table: false,
        topUpCheck: false,
        topUp: false,
    });
    const [tableData, setTableData] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [searchParams] = useSearchParams();
    const search = searchParams.get("search")

    let adminName = ''

    try {
        adminName = JSON.parse(sessionStorage.getItem('adminName'));
    } catch (err) {
        //err
    }

    var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    var today = new Date();
    var d;
    var month = [];

    for(var i = 6; i > 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month.push(monthNames[d.getMonth()]);
    }

    useEffect(() => {
        const filtered = tableData?.filter((item) => 
            item?.businessId?._id === search?.toLowerCase()
        ) 
        setFilteredData(filtered)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])


    useEffect(()=>{
        fetchData()
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchData = () => {
        setIsLoading({...isLoading, table: true})
        WalletService.getAllTopUp("all")
            .then((response) => response.data)
            .then((res) => {
                setIsLoading({...isLoading, table: false})
                if (res.status === "success" ) {
                    setTableData(res.data.reverse())
                    setFilteredData(res.data.reverse())
                } else {
                    toast.error("Something went wrong, please try again!!!")
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
                setIsLoading({...isLoading, table: false})
            });
    }

    const csvConfig = mkConfig({ 
        useKeysAsHeaders: false,
        columnHeaders: [
            { displayLabel: "ID", key: "businessId._id" },
            { displayLabel: "Business Name", key: "businessId.businessName" },
            { displayLabel: "Top-up Amount", key: "topUpAmount" },
            { displayLabel: "Previous Balance", key: "previousBalance" },
            { displayLabel: "New Balance", key: "newBalance" },
            { displayLabel: "Top-up By", key: "topUpBy" },
            { displayLabel: "Time", key: "updatedAt" },
        ],
        filename: "banks"
    });

    const form = useFormik({
        initialValues: {
            environment: '',
            businessId: "",
            currentBalance: "",
            amount: '',
            walletType: '',
        },
        validationSchema: object({
            environment: string().required().label("Environment"),
            businessId: string().when("currentBalance", {
                is: (value) => !!value,
                then: (schema) => schema.optional(),
                otherwise: (schema) => schema.required("Check balance to proceed"),
            }),
            amount: string().required().label("Top up amount"),
            walletType: string().required().label("Wallet Type"),
        }), 
        onSubmit: (values) => {
            if(!!values?.amount){
                handleTopUp(values)
            } else {
                toast.error("Amount is required")
            }
        }
    })

    const handleWalletCheck = () => {
        setIsLoading({...isLoading, topUpCheck: true})
        WalletService.verifyWallet(
            form.values.businessId,
            form.values.environment.toLowerCase()
        )
            .then((response) => response.data)
            .then((res) => {
                setIsLoading({...isLoading, topUpCheck: false})
                if (res.success === true) {
                    toast.success("Check successful!")
                    form.setFieldValue(
                        "currentBalance", 
                        res?.data[`${form.values.walletType}Balance`]
                    );
                } else {
                    toast.error("Something went wrong, please try again!!!")
                }
            })
            .catch((err) => {
                setIsLoading({...isLoading, topUpCheck: false})
                if(err?.response?.data?.message === "Wallet not found"){
                    toast.error("Incorrect business ID, please ensure you're on the right environment");
                    return;
                }
                handleRequestErrors(err)
            });
    }

    const handleTopUp = (values) => {
        setIsLoading({...isLoading, topUp: true})
        WalletService.rechargeWallet(
            JSON.stringify({
                businessId: values?.businessId,
                amount: values?.amount,
                walletType: values?.walletType,
                topUpBy: adminName,
            }),
            values.environment?.toLowerCase()
        )
            .then((response) => response.data)
            .then((res) => {
                setIsLoading({...isLoading, topUp: false})
                if (res.success ===true) {
                    toast.success(res.message)
                    fetchData()
                    form.resetForm()
                } else {
                    toast.error("Something went wrong, please try again!!!")
                }
            })
            .catch((err) => {
                setIsLoading({...isLoading, topUp: false})
                handleRequestErrors(err)
            });
        
    }

    useEffect(()=>{
        form.setFieldValue("currentBalance", "")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.environment, form.values.walletType])
    
    return(
        <div>
            <div className='border-[1px] border-[#F2F4F7] p-[20px] w-[400px] mb-10 rounded-[15px] box-border shadow-[0px_12px_16px_-4px_rgba(16,24,40,0.08),0px_4px_6px_-2px_rgba(16,24,40,0.03)]'>
                <h4 className='mb-[20px] text-xl font-bold'>Wallet Top-up</h4>
                <FormikProvider value={form}>
                    <form onSubmit={form.handleSubmit}>
                        <SelectField
                            name='environment'
                            options={["Development", "Production"].map(item => ({
                                value: item,
                                label: item
                            }))}
                            label="Select Environment"
                            placeholder="Select environment"
                            onChange={(selected)=>{
                                form.setFieldValue( "environment", selected.value )
                            }}
                            value={form?.values?.environment}
                        />
                        <SelectField
                            name='walletType'
                            options={[
                                {label:"Nigerian Naira (NGN)", value:"ngn"},
                                {label:"US Dollar (USD)", value:"usd"},
                                {label:"Kenyan Shilling (KES)", value:"kes"},
                                {label:"Ghanian Cedi (GHS)", value:"ghs"},
                                {label:"South African Rand (ZAR)", value:"zar", isDisabled: true},
                                {label:"Ugandan Shilling (UGX)", value:"ugx", isDisabled: true},
                                {label:"Tanzanian Shilling (TZS)", value:"tzs", isDisabled: true},
                            ].map(item => ({
                                value: item.value,
                                label: item.label,
                                isDisabled: item.isDisabled
                            }))}
                            label="Currency to Top-Up"
                            placeholder="Select Currency"
                            onChange={(selected)=>{
                                form.setFieldValue( "walletType", selected.value )
                            }}
                            value={form?.values?.walletType}
                        />
                        <div className='flex gap-x-2.5 items-center'>
                            <TextField
                                name='businessId'
                                type='text'
                                label="Enter Business ID"
                                placeholder="Business ID"
                            />
                            <Button 
                                text="Check"
                                type="button"
                                isLoading={isLoading.topUpCheck}
                                onClick={()=>{
                                    if(!!form.values.environment && !!form.values.walletType && !!form.values.businessId){
                                        handleWalletCheck()
                                    } else {
                                        form.setTouched({
                                            environment: true,
                                            walletType: true,
                                            businessId: true,
                                        })
                                    }
                                }}
                                className="bg-ee-primary px-3 py-2.5 mt-1 !text-sm !rounded"
                            />
                        </div>
                        {
                            !!form.values.currentBalance &&
                            <p className='text-[#475467] -mt-5 mb-[20px]'>
                                Current Wallet Balance : {" "}
                                {currencyFormatter(
                                    form.values.currentBalance||0,
                                    form.values.walletType.toUpperCase()
                                )}
                            </p>
                        }
                        <CurrencyField
                            onChange={(val)=>{
                                form.setFieldValue("amount", val.value )
                            }}
                            value={form.values.amount}
                            name='amount*'
                            label="Top-up Amount"
                            placeholder="Enter Amount"
                            currencyPrefix={
                                form.values.walletType==="usd" ? '$'
                                : form.values.walletType==="ghs" ? '₵'
                                : form.values.walletType==="ngn" ? '₦'
                                : form.values.walletType.toUpperCase()
                            }
                        />
                        <Button 
                            isLoading={isLoading.topUp}
                            text="Top Up Wallet"
                            type="submit"
                            className="bg-ee-primary px-3 py-2.5 w-full !text-sm !rounded"
                        />
                    </form>
                </FormikProvider>
            </div>
            <div className='mb-10'>
                <div className='flex items-center justify-between'>
                    <h4 className='text-lg font-semibold'>Wallet Top-up History</h4>
                    <SearchField
                        placeholder="Search by name, ID"
                        containerClass='ml-auto my-6'
                    />
                </div>
                <Table
                    loading={isLoading.table}
                    data={filteredData || []}
                    theadClass="!font-bold py-[16px] text-[#667085] bg-[#F0F0F0] text-sm"
                    tdataClass="py-[20px] pl-[10px] text-sm"
                    hideTableTop={true}
                    hideOverallTableBorder={true}
                    hideTableBodyBorder={true}
                    hideLines={true}
                    paginationContainer="!px-0"
                    showDownload={true}
                    onDownloadClick={()=>{
                        if(filteredData.length>0){
                            const csv = generateCsv(csvConfig)(filteredData);
                            download(csvConfig)(csv)
                        }
                    }}
                    rows={[
                        {
                            header:'ID',
                            view: (item) => (
                                <div className='!w-[100px] text-ellipsis overflow-hidden'>
                                    <span>
                                        #{item?.businessId?._id||"---"}
                                    </span>
                                </div>
                            ),
                        },
                        {
                            header:'Business Name',
                            view: (item) => (
                                <span>
                                    {item?.businessId?.businessName||item?.account_name||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'Top-up Amount',
                            view: (item) => (
                                <span>
                                    {currencyFormatter(item?.topUpAmount||0)}
                                </span>
                            ),
                        },
                        {
                            header:'Previous Balance',
                            view: (item) => (
                                <span>
                                    {currencyFormatter(item?.previousBalance||0)}
                                </span>
                            ),
                        },
                        {
                            header:'New Balance',
                            view: (item) => (
                                <span>
                                    {currencyFormatter(item?.newBalance||0)}
                                </span>
                            ),
                        },
                        {
                            header:'Top-up By',
                            view: (item) => (
                                <span className='capitalize'>
                                    {item?.topUpBy||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'Date/Time',
                            view: (item) => (
                                <span>
                                    {dayjs(item?.updatedAt).format("DD/MM/YYYY hh:mmA")}
                                </span>
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default WalletTopup