import React, { useEffect, useState } from 'react'
import Table from '../../../components/Table/general.js';
import { useNavigate, useParams } from 'react-router-dom';
import { getBin, getLogService, getUpdatedBin } from '../../../lib/auth.js';
import { toast } from 'react-toastify';
import cancel from '../../../images/svg/tech-support/cancel.svg'
import Back from '../../../components/back.js'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Loader from '../../../components/shared/Loader.js';

const Details = () => {
    const [showDeets, setShowDeets] = useState(false)
    const [viewData, setViewData] = useState({});
    const [binId, setBinId] = useState('');
    const [newBinId, setNewBinId] = useState('');
    const [binStatus, setBinStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState({
        tableFor: "tech-support",
        headings: ["Bin ID", "Name", "Created", "", ],
        items: []
    });

    let navigate = useNavigate()
    let params = useParams()
    let service = params.service

    if(service === "Pdf Parser"){
      service = 'pdf'
    }
    if(service === "DBR & EDTI"){
      service = 'dbr'
    }
    if(service === "B2BFrontend"){
      service = 'frontb2b'
    }
    if(service === "Float"){
      service = 'float'
    }
    if(service === "Spectrum"){
      service = 'dln'
    }
    if(service === "MFB"){
      service = 'mfb'
    }
    if(service === "EagleEyeAPI"){
      service = 'eagleeye'
    }

    useEffect(() => {
      setIsLoading(true)
      let payload = service.toLowerCase()

      getLogService(payload)
      .then(response => response.data)
      .then((res) => {
          if(res.status === 'success'){
            setTableData({
                ...tableData,
                items : res?.data
              });
              setIsLoading(false)
              if(res?.data?.length >= 10){
                setNewBinId(res?.data?.[9]?.record)
                setBinStatus(true)
              }
          }else {
            toast.error("Something went wrong, please try again!!!")
            setIsLoading(false)
         }
      })
      .catch((err) => {
          if(err.response){
              toast.error(err.response.data.message);
              setIsLoading(false)
          } else{
              toast.error("Something went wrong!!!");
              setIsLoading(false)
          }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const doAction = (item) => {
      let payload = {
        service: service.toLowerCase()
      }
      setBinId(item?.record)
      getBin(item?.record, payload)
      .then(response => response.data)
      .then((res) => {
          if(res.status === 'success'){
            setViewData( res?.data);
          }else {
            toast.error("Something went wrong, please try again!!!")
         }
      })
      .catch((err) => {
          if(err.response){
              toast.error(err.response.data.message);
          } else{
              toast.error("Something went wrong!!!");
          }
        });
    }

    const doUpdate = () => {
      setIsLoading(true)
      let payload = service.toLowerCase()
      getUpdatedBin(payload, newBinId)
      .then(response => response.data)
      .then((res) => {
          if(res.status === 'success'){
            setTableData({
                  ...tableData,
                  items : res?.data
              });
              setIsLoading(false)
              if(res?.data?.length >= 10){
                setNewBinId(res?.data?.[9]?.record)
                setBinStatus(true)
              }
          }else {
            toast.error("Something went wrong, please try again!!!")
            setIsLoading(false)
         }
      })
      .catch((err) => {
          if(err.response){
              toast.error(err.response.data.message);
              setIsLoading(false)
          } else{
              toast.error("Something went wrong!!!");
              setIsLoading(false)
          }
        });

    }
    
  return (
    <div>
      <Back to={()=> navigate("/tech-support")} />
      <div className={showDeets ? 'techSupport_flex' : 'techSupport_block'}>

        <div className={showDeets ? 'techSupport_sixty' : 'techSupport_hundred'}>
        {
          isLoading ?
          <Loader /> :
          <Table 
              data={tableData} 
              doAction={(item)=>{
                setShowDeets(true);
                doAction(item);
              }}
              binStatus={binStatus}
              doUpdate={doUpdate}
            />
        }
            
        </div>
        
        {
            showDeets &&
            <div className="techSupport_details">
                <img src={cancel} alt="" className="cancel" onClick={()=>setShowDeets(false)} />
                {/* <hr /> */}
                <div style={{height:'40px'}}></div>
                <h3>Bin ID</h3>
                {/* <p className="text1">App <span>{binId}</span></p> */}
                <p className="text1">{binId}</p>
                
              {  
              viewData.statusCode &&
                <p className="text1">Status Code 
                    <span>
                        <button className={viewData.status==="success"?"closed":"open"} style={{textTransform:"capitalize"}} >
                            <div></div>
                            {viewData.statusCode}
                        </button>
                    </span>
                </p>
              }
                <div className="techSupport_details-code">
                    <SyntaxHighlighter 
                        language="json" 
                        style={github} 
                        showLineNumbers={true}
                        lineNumberStyle={{textAlign:'left', minWidth: '25px', padding:"0 10px ", marginRight:'15px'}}
                    >
                      {/* {"timestamp:"  } */}
                      {`url: ${JSON.stringify(viewData?.url)} `}
                    </SyntaxHighlighter>

                    <h5>Message</h5>
                    <SyntaxHighlighter 
                        language="json" 
                        style={github} 
                        showLineNumbers={true}
                        lineNumberStyle={{textAlign:'left', minWidth: '25px', padding:"0 10px ", marginRight:'15px'}}
                    >
                      {/* {"timestamp:"  } */}
                        {viewData?.message ? viewData?.message : 'no message'}
                    </SyntaxHighlighter>
                </div>
            </div>
          }

      </div>
    </div>
  )
}

export default Details