import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import api from "images/svg/tech-support/api.svg"
import { createApiService, generateRefreshKey, getApiService } from 'lib/auth';
import CreateService from 'components/Modal/CreateService';
import GenerateKeys from 'components/Modal/GenerateKeys';
import ApiBox from 'components/shared/ApiBox';
import Loader from 'components/shared/Loader';
import { handleRequestErrors } from 'utils/helpers';


const ApiSecurity = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showKeys, setShowKeys] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedService, setSelectedService] = useState('');

  const toggleKeyVisibility = () => {
    setShowKeys(!showKeys)
  }

  const onCopyText = () => {
    toast.success('Keys copied!')
  };


  const handleKeys = () => {
    if(selectedService === "") return;
    const payload = {
      service: selectedService
    }
    generateRefreshKey(payload)
      .then(response => response.data)
      .then((res) => {
        if(res.status === 'success'){
            setShowModal(false)
            toast.success("Successful")
            window.location.reload();
        } else {
            toast.error(res.message)
        }
      }).catch((err) => {
        handleRequestErrors(err)
      });
  }

  const handleCreateService = (serviceName) => {
    const payload = {
      service: serviceName
    }
    createApiService(payload)
      .then(response => response.data)
      .then((res) => {
          if(res.status === 'success'){
              toast.success("Service created successfully")
              window.location.reload()
          } else {
            toast.error("Something went wrong, please try again!!!")
        }
      } )
      .catch((err) => {
        handleRequestErrors(err)
      });
  }

  useEffect(() => {
    setIsLoading(true)
    getApiService()
      .then(response => response.data)
      .then((res) => {
          if(res.status === 'success'){
              setData(res?.data);
              setIsLoading(false)
          }else {
            toast.error("Something went wrong, please try again!!!")
            setIsLoading(false)
        }
      } )
      .catch((err) => {
        handleRequestErrors(err)
        setIsLoading(false)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

     
      
  return (
    <div className='techSupport_apiService'>
        <div className='new' onClick={()=> setShowCreateModal(true)}>
          <img src={api} alt="api icon" />
          <h4> Create New Service </h4>
        </div>

        <div className='main'>
        {
          isLoading ?
          <Loader /> :
          <ApiBox 
            data={data}
            setSelectedService={setSelectedService}
            showKeys={showKeys}
            setShowModal={setShowModal}
            toggleKeyVisibility={toggleKeyVisibility}
            onCopyText={onCopyText}
          />
        }
            

        </div>

        <GenerateKeys 
          show={showModal} 
          cancel={()=>setShowModal(false)} 
          handleKeys={(data)=>handleKeys(data)}
        /> 

        <CreateService 
          show={showCreateModal} 
          // type={type} 
          cancel={()=>setShowCreateModal(false)}
          handleAction= {handleCreateService}
        /> 
    </div>
  )
}

export default ApiSecurity